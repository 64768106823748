const production = {
    sentry: {
        token: 'https://a46739c4f3e147b6a07b3f8636ee043a@sentry.io/1757705'
    },
}

const staging = {
    sentry: {
        token: 'https://f7e5e7f1902940f09a4732ad668667d2@sentry.io/1757023'
    },
}

export const getConfig = () => {
    if(process.env.REACT_APP_BUILD_ENV === 'production') {
        return production;
    }
    return staging;
} 