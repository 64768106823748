import React from 'react';
import styled, { keyframes } from 'styled-components';

export const Loader = props => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="130"
      data-testid="loader"
      height="130"
      viewBox="0 0 130 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="51" cy="59.9" rx="7.5" ry="8" fill="#F99746">
        <animate
          attributeName="cy"
          values="59.9; 56.9; 59.9"
          dur="1s"
          begin="0s"
          repeatCount="indefinite"
          fill="freeze"
        />
      </ellipse>
      <ellipse cx="73.2" cy="49" rx="7.5" ry="8" fill="#F99746">
        <animate
          attributeName="cy"
          values="49; 52; 49"
          dur="1s"
          begin="0s"
          repeatCount="indefinite"
          fill="freeze"
        />
      </ellipse>
      <path
        d="M73.1998 65.7998C66.7998 65.8998 61.1998 69.6998 58.6998 75.5998C56.3998 74.2998 53.7998 73.5998 51.1998 73.5998C42.2998 73.5998 35.0998 81.2998 35.0998 90.7998V99.4998C34.9998 102.4 37.2998 104.8 40.1998 104.9C43.0998 104.8 45.3998 102.4 45.2998 99.4998V90.7998C45.1998 87.3998 47.8998 84.4998 51.2998 84.3998C54.6998 84.4998 57.3998 87.3998 57.2998 90.7998V99.4998C57.1998 102.4 59.4998 104.8 62.3998 104.9C65.2998 104.8 67.5998 102.4 67.4998 99.4998V83.0998C67.3998 79.6998 70.0998 76.7998 73.4998 76.6998C76.8998 76.7998 79.5998 79.6998 79.4998 83.0998V99.5998C79.2998 102.4 81.3998 104.8 84.1998 105C86.9998 105.2 89.3998 103.1 89.5998 100.3C89.5998 100.1 89.5998 99.8998 89.5998 99.5998V83.0998C89.2998 73.5998 81.9998 65.7998 73.1998 65.7998Z"
        fill="#F99746"
      />
      <LeftBuble
        d="M28.7143 36H37.2857C40.4286 36 43 38.7 43 42V48H28.7143C25.5714 48 23 45.3 23 42C23 38.7 25.5714 36 28.7143 36Z"
        fill="#2BAD97"
      />

      <RightBuble
        d="M90.5 24H101.5C105.6 24 109 27.4 109 31.5C109 35.6 105.6 39 101.5 39H83V31.5C83 27.4 86.4 24 90.5 24Z"
        opacity="0"
        fill="#2FB6D6"
      />
    </svg>
  );
};

const leftScale = keyframes`
  0% {
    transform: scale(1);
    opacity: 0;
  }
  25% {
    transform: scale(1.5);
    opacity: 1;
  }
  50% {
    transform: scale(1.85);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`;

const LeftBuble = styled.path`
  transform-origin: 43px 48px;
  animation: ${leftScale} 2s linear infinite;
`;

const rightScale = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 0;
  }
  75% {
    transform: scale(1.33333);
    opacity: 1;
  }
  100% {
    transform: scale(1.66667);
    opacity: 0;
  }
`;

const RightBuble = styled.path`
  transform-origin: 83.2px 38.8px;
  animation: ${rightScale} 2s linear infinite;
`;
