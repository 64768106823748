
import qs from 'qs';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

function getRootUrl() {
    const { REACT_APP_BUILD_ENV = 'local' } = process.env;
    if(REACT_APP_BUILD_ENV === 'local') return 'http://localhost:5000';
    if(REACT_APP_BUILD_ENV === 'staging') return 'https://api-test.snapmentor.no';
    if(REACT_APP_BUILD_ENV === 'production') return 'https://api.snapmentor.no';
    throw Error('Invalid environment');
}


async function validate(token) {
    const res = await axios.get(
        `${getRootUrl()}/v0/auth/tokens/validate?token=${token}`,
    );
    return res.data;
}

function parseStateParam(state) {
    const decoded = decodeURIComponent(state);
    const parts = decoded.split('|');
    console.log(parts);
    if(parts.length < 2) {
        // Sometimes when redirecting from feide, the normal b2c state is not part of the state. 
        // Therefor we select the first and only param if there is only one and cross our fingers for great success
        return parts[0];
    } 
    return parts[1];
}

function useValidateToken() {
    const history = useHistory();
    const hashQuery = qs.parse(history.location.hash.split('#')[1]);
    const token = parseStateParam(hashQuery.state);
    const { data, isFetching, error } = useQuery(['authToken-validate'], () => validate(token));
    return {
        data,
        isFetching,
        error,
    };
}

export default useValidateToken;
