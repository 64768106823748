import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import TokenValidateComponent from './TokenValidateComponent';

const App = () =>
  <Router>
    <Switch>
      <Route path="*"><TokenValidateComponent /></Route>
    </Switch>
  </Router>

export default App;
