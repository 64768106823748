import React, { useEffect } from 'react';

import useValidateToken from './hooks/useValidateToken';
import {
    useLocation,
} from "react-router-dom";
import { Loader } from './components/Loader';
import { FullPageContainer } from './components/FullPageContainer';

import * as Sentry from '@sentry/browser';
import styled from 'styled-components';

const createRedirectLink = (redirectUri, hash) => `${redirectUri}${hash}`

export const isMatch = (referrer) => {
    const snapmentorMatch = new RegExp(/https:\/\/.*\.snapmentor\.no/);
    const curipodMatch = new RegExp(/https:\/\/.*\.curipod\.com/);
    return snapmentorMatch.test(referrer) || curipodMatch.test(referrer);
}

const shouldSendError = () => {
    if(window.location.href.includes('AADB2C90077')) {
        // AADB2C90077 is the error thrown by azure ad b2c for "interaction required". Which means the user needs a full page refresh to get a new access token for authorization
        return false;
    }
    //In most cases, send the error.
    return true;
}

const TokenValidateComponent = () => {
    const {
        data,
    } = useValidateToken();

    const location = useLocation();

    useEffect(() => {
        if (data?.isValid && location) {
            console.log('URL:', window.location.href);
            console.log('HASH:', location.hash);
            console.log('Redirecting to', createRedirectLink(data.redirectUri, location.hash));
            window.location.href = createRedirectLink(data.redirectUri, location.hash);
        }

        if (data && !data.isValid) {
            const err = new Error('ID_APP_REDIRECT_FAILED');
            const sendError = shouldSendError();
            if(sendError) {
                Sentry.captureException(err);
            }
            console.log('Redirecting to troubleshooting');
            console.log('Referrer', document?.referrer);
            const hasPotentialReferrer = isMatch(document.referrer);
            if(hasPotentialReferrer) {
                setTimeout(() => {
                    window.location.href = document.referrer;
                }, 2000)
            }else {
                setTimeout(() => {
                    window.location.href = 'https://curipod.com/troubleshooting';
                }, 2000)
            }
        }
    }, [data, location]);

    let testId = '';
    if (data && !data.isValid) {
        testId = "Invalid state";
    }

    if (data?.isValid) {
        testId = `Redirecting to ${createRedirectLink(data.redirectUri, location.hash)}`;
    }



    return <FullPageContainer data-testid={testId}>
        {data && !data.isValid ? <ContactSupport><p data-testid="troubleshooting">Whooops. We are terribly sorry! Something went wrong with your login.</p><p>You are now being redirected to our troubleshooting page.</p></ContactSupport> : <Loader />}
    </FullPageContainer>;
}

const ContactSupport = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span {
        font-size: 20px;
    }

    p, a {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        width: 100%;
    }
`;

export default TokenValidateComponent;